<div class="account-pages my-5 pt-sm-5">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-md-8 col-lg-6 col-xl-5">
        <div class="card overflow-hidden">
          <div class="bg-soft bg-primary">
            <div class="row">
              <div class="col-7">
                <div class="text-primary p-4">
                  <h5 class="text-primary">Welcome Back !</h5>
                  <p>Sign in to continue to PiMS.</p>
                </div>
              </div>
              <div class="col-5 align-self-end">
                <img src="assets/images/profile-img.png" alt="" class="img-fluid">
              </div>
            </div>
          </div>
          <div class="card-body pt-0">
            <div>
              <a routerLink="/">
                <div class="avatar-md profile-user-wid mb-4">
                  <span class="avatar-title rounded-circle bg-light">
                    <img src="assets/images/logo.svg" alt="" class="rounded-circle" height="34">
                  </span>
                </div>
              </a>
            </div>
            <div class="p-2">
              <form class="form-horizontal">

                <ngb-alert type="danger" *ngIf="error" [dismissible]="false">{{ error }}</ngb-alert>

                <div class="mb-3">
                  <label for="username">Username</label>
                  <input type="username" name="username" class="form-control" id="username" placeholder="username" [(ngModel)]="loginForm.username" />
                </div>

                <div class="mb-3">
                  <label for="password">Password</label>
                  <div class="input-group auth-pass-inputgroup">
                    <input type="password" class="form-control" name="password" placeholder="Enter password" aria-describedby="password-addon" [(ngModel)]="loginForm.password">

                    <button class="btn btn-light ms-0" type="button" id="password-addon"><i class="mdi mdi-eye-outline"></i></button>
                  </div>
                </div>

                <!--div class="form-check">
                  <input class="form-check-input" type="checkbox" id="remember-check">
                  <label class="form-check-label" for="remember-check">
                    Remember me
                  </label>
                </div-->

                <div class="mt-3 d-grid">
                  <button class="btn btn-primary" type="submit" (click)="onSubmit()">Log In</button>
                </div>
                <div class="mt-4 text-center">
                  <a routerLink="/account/reset-password" class="text-muted"><i class="mdi mdi-lock mr-1"></i> Forgot
                    your
                    password?</a>
                </div>
              </form>
            </div>

          </div>
        </div>
        <div class="mt-5 text-center">
          
        </div>

      </div>
    </div>
  </div>

  <!-- end container -->
</div>
<!-- end page -->