import { Component, OnInit } from '@angular/core';
import { FormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

import { AuthenticationService } from '../../../core/services/auth.service';
import { AuthfakeauthenticationService } from '../../../core/services/authfake.service';

import { ActivatedRoute, Router } from '@angular/router';

import { environment } from '../../../../environments/environment';
import { RestService } from 'src/app/core/services/rest.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})

/**
 * Login component
 */
export class LoginComponent implements OnInit {

  loginForm: any = {
    username: '',
    password: ''
  };
  submitted = false;
  error = '';
  returnUrl: string;

  // set the currenr year
  year: number = new Date().getFullYear();

  // tslint:disable-next-line: max-line-length
  constructor(private route: ActivatedRoute, private router: Router, private authenticationService: AuthenticationService,
    private authFackservice: AuthfakeauthenticationService, private auth: AuthenticationService, private rest: RestService) { }

  ngOnInit() {

    // reset login status
    // this.authenticationService.logout();
    // get return url from route parameters or default to '/'
    // tslint:disable-next-line: no-string-literal
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
  }

  /**
   * Form submit
   */
  onSubmit() {
    this.submitted = true;

    this.auth.login("login","/jwt/authenticate",this.loginForm).subscribe({
      next: (res: any) => {
        console.debug("OK",res);
        this.rest.tkn = res.token;
        sessionStorage.setItem('tkn',res.token);
        this.router.navigate(['/application']);
      },
      error: (err)=> {
        console.debug("KO",err);
      }
    })

  }
}
