import { Injectable } from '@angular/core';

import { getFirebaseBackend } from '../../authUtils';

import { User } from '../models/auth.models';
import {Router} from "@angular/router";
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';

@Injectable({ providedIn: 'root' })

export class AuthenticationService {

    user: User;
    restURLs: any = environment.restURL;

    constructor(private router: Router, private httpClient: HttpClient) {
    }

    /**
     * Returns the current manage
     */
    public currentUser(): User {
        return getFirebaseBackend().getAuthenticatedUser();
    }

    /**
     * Performs the auth
     * @param email email of manage
     * @param password password of manage
     */
    login(base:string, ctx:string, json: any, opt?:any) {
        console.debug(this.restURLs, base,ctx, json);

        return this.httpClient.post(this.restURLs[base] + '' + ctx, json, opt).pipe(
            catchError((err) => {
            console.log('error caught in service')
            console.error(err);

            //Handle the error here

            return throwError(err);    //Rethrow it back to component
            })
        );
    }

    /**
     * Performs the register
     * @param email email
     * @param password password
     */
    register(email: string, password: string) {
        return getFirebaseBackend().registerUser(email, password).then((response: any) => {
            const user = response;
            return user;
        });
    }

    /**
     * Reset password
     * @param email email
     */
    resetPassword(email: string) {
        return getFirebaseBackend().forgetPassword(email).then((response: any) => {
            const message = response.data;
            return message;
        });
    }

    /**
     * Logout the manage
     */
    logout() {
        // logout the manage
        getFirebaseBackend().logout();
    }
}

