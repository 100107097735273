import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { AuthenticationService } from '../services/auth.service';
import {SweetAlertOptions} from 'sweetalert2';
import {GuiService} from '../services/gui.service';
@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    constructor(
        private authenticationService: AuthenticationService,
        private gui: GuiService
        ) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(catchError(err => {
            if (err.status === 400) {
                // auto logout if 400 response returned from api

                let opt: SweetAlertOptions = {
                    title: 'Errore!',
                    text: 'Errore nel salvataggio: '+err.statusText+' - '+err.statusText,
                    icon: 'error',
                    showCancelButton: false,
                    confirmButtonColor: 'rgb(247, 204, 83)',
                    confirmButtonText: 'OK'
                };

                this.gui.showMessage(opt);
            } else if (err.status === 500) {
                // auto logout if 400 response returned from api

                let opt: SweetAlertOptions = {
                    title: 'Errore!',
                    text: 'Errore nel salvataggio: '+err.statusText+' - '+err.statusText,
                    icon: 'error',
                    showCancelButton: false,
                    confirmButtonColor: 'rgb(219 54 70)',
                    confirmButtonText: 'OK'
                };

                this.gui.showMessage(opt);

            } else if (err.status === 401) {
                // auto logout if 401 response returned from api
                this.authenticationService.logout();
                location.reload();
            }

            const error = err.error.message || err.statusText;
            return throwError(error);
        }));
    }
}
