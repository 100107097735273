import { TranslateService } from '@ngx-translate/core';
import {EventEmitter, Injectable, Input, Output} from '@angular/core';
import { Router } from '@angular/router';
import {Observable, ReplaySubject} from "rxjs";

import Swal, {SweetAlertOptions} from 'sweetalert2';
import {SelectedProperty} from '../models/selected-property';
import {Property} from '../models/property.models';
import {NgbDate} from '@ng-bootstrap/ng-bootstrap';



@Injectable({
  providedIn: 'root'
})
export class GuiService {

  userLogged: any;
  showSpinner = true;
  languages = this.translate.getLangs();
  app_ctx: any = null;
  app_area: any = null;

  selectedProperty: SelectedProperty;
  managedProperty: Property;
  page_divider = false;

  max_row: Array<number> = [5,10,25,50,1];

  notificationTypes = {
    subscription: {colorClass: 'text-primary', icon: 'mat:account_circle'},
    info: {colorClass: 'text-orange', icon: 'mat:insert_chart'},
    default: {colorClass: 'text-green', icon: 'mat:check_circle'}
  };

  // Date range picker
  hoveredDate: NgbDate;
  fromNGDate: NgbDate;
  toNGDate: NgbDate;
  hidden: boolean;
  selected: any;
  @Input() fromDate: Date;
  @Input() toDate: Date;
  @Output() dateRangeSelected: EventEmitter<{}> = new EventEmitter();

  constructor(private translate: TranslateService, private router: Router) {
    this.selectedProperty = new SelectedProperty();
  }


  changeLang(lang: any){
    console.debug(lang)
    this.translate.use(lang)
  }


  openSnackBar(message: string, action?: any, typ?: string, time?:number) {
  }

  showMessage(opt: SweetAlertOptions) {
    let option: SweetAlertOptions = {};

    opt.title ? option['title'] = opt.title : null;
    option['icon'] = 'info';
    opt.html ? option['html'] = opt.html : null;
    opt.showCloseButton ? option['showCloseButton'] = opt.showCloseButton : false;
    opt.showCancelButton ? option['showCancelButton'] = opt.showCancelButton : false;
    opt.focusConfirm ? option['focusConfirm'] = opt.focusConfirm : false;
    opt.confirmButtonText ? option['confirmButtonText'] = opt.confirmButtonText : null;
    opt.confirmButtonAriaLabel ? option['confirmButtonAriaLabel'] = opt.confirmButtonAriaLabel : null;
    opt.cancelButtonText ? option['cancelButtonText'] = opt.cancelButtonText : null;
    opt.cancelButtonAriaLabel ? option['cancelButtonAriaLabel'] = opt.cancelButtonAriaLabel : null;
    opt.confirmButtonColor ? option['confirmButtonColor'] = opt.confirmButtonColor : null;
    opt.cancelButtonColor ? option['cancelButtonColor'] = opt.cancelButtonColor : null;
    
    
    Swal.fire(option)
  }

  prettyJSON(eleId) {
    var ugly = (<HTMLInputElement>document.getElementById("_"+eleId)).value;
    if(ugly.length>6) {
      var obj = JSON.parse(ugly);
      var pretty = JSON.stringify(obj, undefined, 3);
      (<HTMLInputElement>document.getElementById("_"+eleId)).value = pretty;
    }
  }

  counter(i: number) {
    return new Array(i);
  }



  /**
   * Is hovered over date
   * @param date date obj
   */
  isHovered(date: NgbDate) {
    return this.fromNGDate && !this.toNGDate && this.hoveredDate && date.after(this.fromNGDate) && date.before(this.hoveredDate);
  }
  /**
   * @param date date obj
   */
  isInside(date: NgbDate) {
    return date.after(this.fromNGDate) && date.before(this.toNGDate);
  }

  /**
   * @param date date obj
   */
  isRange(date: NgbDate) {
    return date.equals(this.fromNGDate) || date.equals(this.toNGDate) || this.isInside(date) || this.isHovered(date);
  }
  /**
   * on date selected
   * @param date date object
   */
  onDateSelection(date: NgbDate) {
    if (!this.fromDate && !this.toDate) {
      this.fromNGDate = date;
      this.fromDate = new Date(date.year, date.month - 1, date.day);
      this.selected = '';
    } else if (this.fromDate && !this.toDate && date.after(this.fromNGDate)) {
      this.toNGDate = date;
      this.toDate = new Date(date.year, date.month - 1, date.day);
      this.hidden = true;
      this.selected = this.fromDate.toLocaleDateString() + '-' + this.toDate.toLocaleDateString();
      this.dateRangeSelected.emit({ fromDate: this.fromDate, toDate: this.toDate });

      this.fromDate = null;
      this.toDate = null;
      this.fromNGDate = null;
      this.toNGDate = null;

    } else {
      this.fromNGDate = date;
      this.fromDate = new Date(date.year, date.month - 1, date.day);
      this.selected = '';
    }
  }

}
